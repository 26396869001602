import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../components/layout';
import Button from '../components/button';
import ServiceCard from '../components/service-card';
import PortfolioCard from '../components/portfolio-card';
import Testimonials from '../components/testimonials';
import Contact from '../components/contact';
import Img from '../components/image';
import SEO from '../components/seo';

import { Link } from 'react-scroll';
import { Fade, Slide, Zoom } from 'react-awesome-reveal';

// Icons
import WebsiteIcon from '../svgs/website.svg';
import IntroIcon from '../svgs/intro.svg';
import WebAppIcon from '../svgs/webapp.svg';
import MobileAppIcon from '../svgs/mobile.svg';
import PlanIcon from '../svgs/plan.svg';
import DesignIcon from '../svgs/design.svg';
import DevelopIcon from '../svgs/develop.svg';
import ReleaseIcon from '../svgs/release.svg';
import RepeatIcon from '../svgs/repeat.svg';

const description =
  'Dewebgineer Solutions is a Software Development Agency that creates applications that help you grow and scale your business operations';

const Index: React.FC<PageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Web & Mobile Development"
        description={description}
        keywords={[
          'web development, mobile app development',
          'website development',
        ]}
      />
      <div className="w-full">
        <section id="home" className="home-container py-16">
          <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row-reverse md:px-3 pb-5">
            <div className="img-container mx-auto md:w-1/2 px-4">
              <Fade duration={1200} triggerOnce>
                <div>
                  <IntroIcon
                    className="h-48 w-56 md:h-auto md:w-full"
                    alt="web development solutions"
                  />
                </div>
              </Fade>
            </div>
            <div className="intro-container flex-col justify-center px-3 text-center md:w-1/2 md:text-left md:flex">
              <h1 className="text-3xl text-white md:text-4xl font-semibold mb-3">
                High Quality Software To Grow Your Business
              </h1>
              <p className="text-lg text-white mb-6">
                We provide software solutions tailored to the needs of your
                business to facilitate growth and scale.
              </p>
              <div className="flex flex-col md:flex-row justify-between px-2 md:justify-start md:px-0">
                <Link to="contact" smooth={true}>
                  <Button className="mb-2 md:mr-3 w-3/5 md:w-auto">
                    Get Started
                  </Button>
                </Link>
                <Link to="services" smooth={true}>
                  <Button theme="secondary" className="w-3/5 md:w-auto">
                    Our Services
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="mb-6 py-10">
          <div className="max-w-screen-lg mx-auto md:px-3 py-3">
            <div className="px-4 text-center mb-12">
              <h1 className="text-3xl font-bold text-royal-blue mb-5">
                Our Services
              </h1>
              <p>
                We build robust applications and experiences using the latest
                and most advanced technologies to provide you with performant
                business solutions.
              </p>
            </div>
            <div className="grid cards px-4 py-6">
              <Fade delay={200} duration={1500} triggerOnce className="h-full">
                <div className="h-full">
                  <ServiceCard
                    title="Web App Development"
                    icon={
                      <WebAppIcon
                        height="35"
                        width="35"
                        className="fill-current"
                      />
                    }
                  >
                    We bring your ideas to life to change the world. We use our
                    expertise in software development to build scalable web
                    applications conforming to industry standards and best
                    practices.
                  </ServiceCard>
                </div>
              </Fade>
              <Fade duration={1500} delay={400} triggerOnce className="h-full">
                <div className="h-full">
                  <ServiceCard
                    title="Website Development"
                    icon={
                      <WebsiteIcon
                        height="35"
                        width="35"
                        className="fill-current"
                      />
                    }
                  >
                    We help you secure your piece of digital real estate.
                    Whether its to advertise your products or services, we
                    provide responsive websites that place you in the best
                    position to reach new and existing customers.
                  </ServiceCard>
                </div>
              </Fade>
              <Fade duration={1500} delay={600} triggerOnce className="h-full">
                <div className="h-full">
                  <ServiceCard
                    title="Mobile App Development"
                    icon={
                      <MobileAppIcon
                        height="35"
                        width="35"
                        className="fill-current"
                      />
                    }
                  >
                    Looking to break into the mobile market? We take the idea
                    and evaluate its applicability to the mobile market and
                    begin to develop your application for iOS and Android
                    Operating Systems.
                  </ServiceCard>
                </div>
              </Fade>
            </div>
          </div>
        </section>
        <section id="about" className="py-10 bg-indigo-dye">
          <div className="max-w-screen-lg mx-auto p-3">
            <div className="px-4 text-white text-center mb-5">
              <h1 className="text-3xl font-bold mb-5">About Dewebgineer</h1>
              <p className="text-lg">
                Dewebgineer Solutions is a software development agency based in
                beautiful Barbados. We provide you with the tools you need to
                expand your online presence or improve your operations through
                the use of tech.
              </p>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="img-container mx-auto w-full md:w-1/2 px-2">
                <Slide direction="left" delay={200} triggerOnce>
                  <div>
                    <Img
                      className="h-auto w-full md:max-w-full"
                      src="about.png"
                      alt="about dewebgineer development agency"
                    />
                  </div>
                </Slide>
              </div>
              <div className="text-white py-4 px-3 md:w-1/2">
                <Fade delay={300} triggerOnce>
                  <div>
                    <h2 className="text-xl mb-2 font-bold">
                      Dewebgineer Solutions
                    </h2>
                    <h3 className="text-lg mb-2 italic">
                      Software Development Agency
                    </h3>
                    <p className="mb-2">
                      We take an iterative approach to our projects where we
                      deliver small changes to you frequently to ensure that we
                      receive your valuable feedback along the way.
                    </p>
                    <p className="mb-1">
                      We deliver value using the following steps:
                    </p>
                    <ol>
                      <li className="flex items-center py-1">
                        <PlanIcon
                          height="20"
                          width="20"
                          fill="#2CA58D"
                          className="mr-2"
                        />
                        Plan
                      </li>
                      <li className="flex items-center py-1">
                        <DesignIcon
                          height="20"
                          width="20"
                          fill="#2CA58D"
                          className="mr-2"
                        />
                        Design
                      </li>
                      <li className="flex items-center py-1">
                        <DevelopIcon
                          height="20"
                          width="20"
                          fill="#2CA58D"
                          className="mr-2"
                        />
                        Develop
                      </li>
                      <li className="flex items-center py-1">
                        <ReleaseIcon
                          height="20"
                          width="20"
                          fill="#2CA58D"
                          className="mr-2"
                        />
                        Review and Release
                      </li>
                      <li className="flex items-center py-1">
                        <RepeatIcon
                          height="20"
                          width="20"
                          fill="#2CA58D"
                          className="mr-2"
                        />
                        Repeat
                      </li>
                    </ol>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="process"></section> */}
        <section id="portfolio" className="py-16 bg-white-alt">
          <div className="max-w-screen-lg mx-auto">
            <div className="px-4 text-center mb-6">
              <h1 className="text-3xl font-bold text-royal-blue mb-5">
                Our portfolio
              </h1>
              <p className="text-lg">
                Below are some examples of our most recent projects. You can
                browse this section to find examples of our work.
              </p>
            </div>
            <div className="grid cards cards--small-gap px-4 py-6">
              <Zoom triggerOnce>
                <PortfolioCard
                  title="Cloud Vision Website"
                  image="cloudvision"
                  altText="Cloudvision Website"
                  description="Marketing website for a new company advertising the services they offer"
                  link="https://cloudvisiongroup.com"
                />
              </Zoom>
              <Zoom delay={200} triggerOnce>
                <PortfolioCard
                  title="Caribbean Home Swap"
                  image="caribbeanhomeswap"
                  altText="Caribbean Home Swap Website"
                  description="An application that allows people from around the world to swap their homes"
                  link="https://caribbeanhomeswap.com/"
                />
              </Zoom>
              {/* <PortfolioCard title="" /> */}
            </div>
          </div>
        </section>
        <section id="testimonials" className="testimonials py-16">
          <div className="max-w-screen-lg mx-auto">
            <div className="px-4 text-center text-white mb-5">
              <h1 className="text-3xl font-bold mb-2">Testimonials</h1>
              <p className="text-lg">What People Say About Us</p>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="img-container mx-auto w-full px-2 md:w-1/2 md:px-3 z-40">
                <Fade direction="left" delay={200} triggerOnce>
                  <div>
                    <Img
                      className="h-auto w-full md:max-w-full"
                      src="testimonials.png"
                      alt="about dewebgineer development agency"
                    />
                  </div>
                </Fade>
              </div>
              <Fade
                // direction="right"
                delay={200}
                triggerOnce
                className="py-4 px-3 md:w-1/2 testimonials-container"
              >
                {/* <Fade direction="right" delay={200} triggerOnce> */}
                <Testimonials />
                {/* </Fade> */}
              </Fade>
            </div>
          </div>
        </section>
        <section id="contact" className="mb-6 py-16">
          <div className="max-w-screen-lg mx-auto">
            <div className="px-4 text-center mb-10 max-w-screen-md mx-auto md:px-16">
              <h1 className="text-3xl font-bold text-royal-blue mb-3">
                Want to Start a Project?
              </h1>
              <p className="text-lg">
                Complete the form below with details about your project and
                let&apos;s get the process started!
              </p>
            </div>
            <Fade
              direction="top"
              triggerOnce
              className="max-w-screen-md mx-auto"
            >
              <div className="md:px-16">
                <Contact />
              </div>
            </Fade>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Index;
